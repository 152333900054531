import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { language } from '@utils/language-utils'
import { Language } from '@api/endpoints/types'

export const useGetLanguage = (): Language => {
  const { i18n } = useTranslation()

  return React.useMemo(() => language(i18n.language), [i18n.language])
}
