import * as React from 'react'
import { getTranslatedField } from '@utils/language-utils'
import { TranslatedString } from '@api/endpoints/types'
import { useGetLanguage } from '@hooks/useGetLanguage'

type TranslatedStringFn = (value: TranslatedString) => string

export const useTranslatedValue = (): TranslatedStringFn => {
  const language = useGetLanguage()

  return React.useCallback((value): string => value[getTranslatedField(language)], [language])
}
